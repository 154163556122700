
export default function Philosophy() {
    return (
        <>
            <div className='blog-container'>
                <div className='blog-header-wrapper'>
                    <p className='blog-header'>
                        Philosophy
                    </p>
                </div>

                <div className='blog-content-wrapper'>
                    <p className='philosophy-paragraph'>
                        Our vision for cloud apps is built on the following pillars:
                    </p>

                    <p className='bold'>Security</p>
                    <p>
                        First-class security is necessary for solid cloud solutions.
                        It’s a must for enterprise companies. Our apps are secure-by-design.
                        That's why we chose Forge as a platform powering our apps and that's why we use Forge storage
                        provided by Atlassian to keep the data.
                    </p>

                    <p className='bold'>Data privacy</p>
                    <p>
                        We design our apps to process as minimal amount of data as required to provide the
                        functionality.
                        We’re transparent in how we handle our customers’ data.
                    </p>

                    <p className='bold'>Reliability</p>
                    <p>
                        We put a focus on making sure our apps are available and working.
                        Without reliability, there’s nothing. It’s our specialization, which we have been learning in
                        Atlassian
                        by discussing reliability solutions and reviewing team’s changes regarding metrics, alerts, SLOs
                        and synthetic tests. We know how to do it well.
                    </p>

                    <p className='bold'>Customer focus</p>
                    <p>
                        We seek points in which we can provide the most value.
                        We strive to truly understand our customers’ problems and help along the whole way of app
                        lifecycle:
                        since the first meet with our brand, through app installation, configuration, support, and if
                        you
                        need any help with uninstall and after uninstall - we’re here for you.
                    </p>

                    <p className='bold'>Communication</p>
                    <p>
                        We aim to maintain good communication channels and relationships with our customers.
                        Understanding each other’s well allows us to respond to ever-changing needs quickly
                        and improve our apps. It’s a way to the mutual success.
                    </p>

                    <p className='bold'>Deep research</p>
                    <p>
                        Comprehensive analysis of the problem, gathering details from various sources, drawing pros &
                        cons,
                        and finally choosing the best way from multiple possible ones is our way of work.
                    </p>

                    <p className='bold'>Continuous improvement</p>
                    <p>
                        The core of our engineering culture is to become better day by day in every aspect of our work,
                        to demand more and more from ourselves and to provide higher and higher quality products &
                        services
                        to our customers. Let us know what we can improve.
                    </p>
                </div>

            </div>
        </>
    )
}