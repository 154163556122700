export default function PrivacyPolicy() {
    return (
        <>
            <div className='blog-container'>
                <div className='blog-header-wrapper privacy-header-wrapper'>
                    <p className='blog-header'>
                        Privacy Policy - Atlassian Marketplace apps
                    </p>
                </div>
                <div className='blog-content-wrapper blog-content-wrapper-privacy-apps'>
                    <p className='privacy-paragraph'>
                        We understand that privacy is a highly important aspect for our customers. In this document we
                        describe how we gather and process customer data.
                    </p>

                    <p className='privacy-paragraph'>
                        We gather only the minimum necessary to provide the services and handle it with maximum caution.
                        Keeping our customers' data safe is a major aspect in how we see the professional software
                        business.
                    </p>

                    <p className='privacy-paragraph'>
                        Why do we collect your personal data

                        <ul className='list-privacy'>
                            <li>
                                to provide apps functionality
                            </li>

                            <li>
                                to provide support services on our support portal
                            </li>
                        </ul>
                    </p>

                    <p className='privacy-paragraph'>
                        What data we collect about you - apps

                        <ul className='list-privacy'>
                            <li>
                                accountId
                            </li>
                        </ul>
                    </p>

                    <p className='privacy-paragraph'>
                        What data we collect about you - support portal

                        <ul className='list-privacy'>
                            <li>
                                name
                            </li>
                            <li>
                                e-mail address
                            </li>
                        </ul>
                    </p>

                    <p className='privacy-paragraph'>
                        What data we collect about you - data contained within Atlassian Marketplace billing report and
                        as a customer technical contact information provided by Atlassian

                        <ul className='list-privacy'>
                            <li>
                                billing / technical contact name
                            </li>
                            <li>
                                billing / technical contact e-mail
                            </li>
                            <li>
                                address
                            </li>
                            <li>
                                phone number
                            </li>
                        </ul>
                    </p>

                    <p className='privacy-paragraph'>
                        How we get the information

                        <ul className='list-privacy'>
                            <li>
                                apps - the information is provided by users using the apps
                            </li>
                            <li>
                                support - the information is provided by the user creating a support ticket
                            </li>
                            <li>
                                billing / technical contact - the information is provided by Atlassian on the time of
                                purchasing the app or starting the trial period
                            </li>
                        </ul>
                    </p>

                    <p className='privacy-paragraph'>
                        How we use information we collect

                        <ul className='list-privacy'>
                            <li>
                                to provide apps functionality
                            </li>
                            <li>
                                to provide support services: to discuss and investigate the problem, and to improve any
                                potential problems in our products
                            </li>
                            <li>
                                to communicate with you regarding our products, to send you technical information, to
                                remind you of the upcoming subscription expirations, to communicate changes in our apps,
                                updates and guides
                            </li>
                            <li>
                                to comply with legal obligations: taxation and accounting
                            </li>
                        </ul>
                    </p>

                    <p className='privacy-paragraph'>
                        How we share information we collect

                        <ul className='list-privacy'>
                            <li>
                                we don’t share the information anywhere - the customer data is kept within Atlassian
                                infrastructure at all time
                            </li>
                        </ul>
                    </p>

                    <p className='privacy-paragraph'>
                        How we store the information we collect

                        <ul className='list-privacy'>
                            <li>
                                apps - we store the data in&nbsp;
                                <a href='https://developer.atlassian.com/platform/forge/runtime-reference/storage-api/'
                                   target='_blank'>Forge Storage</a>
                            </li>
                            <li>
                                support portal - the data is stored in Jira Service Management
                            </li>
                            <li>
                                billing / technical contact - the data is stored within Atlassian Marketplace
                            </li>
                            <li>
                                data is stored only within Atlassian infrastructure
                            </li>
                        </ul>
                    </p>

                    <p className='privacy-paragraph'>
                        In what countries the data is stored

                        <ul className='list-privacy'>
                            <li>
                                apps data - Forge Storage is fully maintained and operated by Atlassian

                                <ul className='list-nested-privacy'>
                                    <li>
                                        if the customer doesn’t use&nbsp;
                                        <a href='https://developer.atlassian.com/platform/forge/data-residency/#data-residency'
                                           target='_blank'>Data Residency</a>
                                        , the data stored in Storage is hosted in the same location as the main product
                                        e.g. Jira
                                    </li>

                                    <li>
                                        if the customer uses Data Residency, it’s stored in the customer’s country of
                                        choice.
                                        The available countries are listed&nbsp;
                                        <a href='https://developer.atlassian.com/platform/forge/data-residency/#supported-locations'
                                           target='_blank'>here</a>
                                        . More details about the process&nbsp;
                                        <a href='https://support.atlassian.com/security-and-access-policies/docs/move-data-to-another-location/'
                                           target='_blank'>here</a>.
                                        More information about hosted storage data location can be found&nbsp;
                                        <a href='https://developer.atlassian.com/platform/forge/changelog/#CHANGE-1302'
                                           target='_blank'>in this update</a>.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                support portal - Europe
                            </li>
                        </ul>
                    </p>

                    <p className='privacy-paragraph'>
                        How we transfer the information we collect internationally

                        <ul className='list-privacy'>
                            <li>
                                in case of a customer change in Data Residency preferences, the data is migrated
                                automatically by Atlassian
                            </li>
                            <li>
                                it is fully controlled by Atlassian
                            </li>
                        </ul>
                    </p>

                    <p className='privacy-paragraph'>
                        What third-party tools we use

                        <ul className='list-privacy'>
                            <li>
                                Atlassian (Forge Platform, Forge Storage) to provide app functionality and store app
                                data
                            </li>
                            <li>
                                Atlassian (Jira Service Management) to provide support services
                            </li>
                        </ul>
                    </p>

                    <p className='privacy-paragraph'>
                        How long we keep the information

                        <ul className='list-privacy'>
                            <li>
                                Forge hosted storage retains data for&nbsp;
                                <a href='https://developer.atlassian.com/platform/forge/storage/'
                                   target='_blank'>28 days after uninstallation</a>
                            </li>
                            <li>
                                we can request Atlassian to delete the data from Forge Storage earlier - in such case,
                                please contact us at support@acceleration-engineering.com
                            </li>
                        </ul>
                    </p>

                    <p className='privacy-paragraph'>
                        What rights do you have

                        <ul className='list-privacy'>
                            <li>
                                the right to erasure
                            </li>
                            <li>
                                the right to rectification
                            </li>
                            <li>
                                the right to be informed how we collect and use your personal data
                            </li>
                            <li>
                                the right to access your personal data
                            </li>
                        </ul>
                    </p>

                    <p className='privacy-paragraph'>
                        In case of any requests or questions regarding privacy policy

                        <ul className='list-privacy'>
                            <li>
                                please contact us at support@acceleration-engineering.com
                            </li>
                        </ul>
                    </p>
                </div>


                <div className='blog-header-wrapper privacy-header-wrapper-website'>
                    <p className='blog-header privacy-header'>
                        Privacy Policy - website
                    </p>
                </div>
                <div className='blog-content-wrapper'>
                    <p className='privacy-paragraph'>
                        Why do we collect your data
                        <br/>
                        • to inform you about new apps we’ll publish to Atlassian Marketplace and about news regarding
                        our company.
                    </p>

                    <p className='privacy-paragraph'>
                        What data we collect about you
                        <br/>
                        • e-mail for the newsletter.
                    </p>

                    <p className='privacy-paragraph'>
                        How we get the information
                        <br/>
                        • you provide it to us when you click submit in the newsletter signup form.
                    </p>

                    <p className='privacy-paragraph'>
                        How we use the information we collect
                        <br/>
                        • only for the purpose of sending you an email regarding new apps and company news.
                    </p>

                    <p className='privacy-paragraph'>
                        How we share the information we collect
                        <br/>
                        • the e-mail address is automatically stored in MailChimp service. Besides that, we don’t share
                        it with anyone and anywhere.
                    </p>

                    <p className='privacy-paragraph'>
                        How we store the information we collect
                        <br/>
                        • the e-mail address is stored in MailChimp service. We don’t have our own database for keeping
                        the data.
                    </p>

                    <p className='privacy-paragraph'>
                        In what countries the data is stored
                        <br/>
                        • the e-mail address is stored in United States.
                    </p>

                    <p className='privacy-paragraph'>
                        How we transfer the information we collect internationally
                        <br/>
                        • we do not transfer the data we collect internationally.
                    </p>

                    <p className='privacy-paragraph'>
                        How long we keep the information
                        <br/>
                        • until you click “Unsubscribe” in the e-mail footer or until you ask us to remove your e-mail
                        by contacting us on business@acceleration-engineering.com
                    </p>

                    <p className='privacy-paragraph'>
                        How to control your information
                        <br/>
                        • you can remove your e-mail address from our newsletter by clicking “Unsubscribe” in the e-mail
                        footer or by sending us an e-mail with a request on business@acceleration-engineering.com.
                    </p>

                    <p className='privacy-paragraph'>
                        What third-party tools we use
                        <br/>
                        • We use&nbsp;
                        <a href="https://plausible.io/"
                           target='_blank'
                        >Plausible.io
                        </a>
                        , an open-source and privacy-friendly analytics tool to see the
                        trends in page traffic. See&nbsp;
                        <a href="https://plausible.io/about"
                           target='_blank'
                        >About Plausible
                        </a>
                        &nbsp;page to understand the philosophy behind it. Here’s Plausible’s&nbsp;
                        <a href="https://plausible.io/data-policy"
                           target='_blank'
                        >data policy
                        </a>
                        . Plausible does not require cookies.
                        <br/>
                        • We use&nbsp;
                        <a href="https://mailchimp.com/"
                           target='_blank'
                        >
                            MailChimp
                        </a>
                        &nbsp;for the e-mail newsletter service.&nbsp;
                        <a href="https://mailchimp.com/legal/terms/"
                           target='_blank'
                        >Here
                        </a>
                        &nbsp;you can find MailChimp’s Terms of Use.
                    </p>
                </div>
            </div>
        </>
    )
}